.icon-section {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    text-align: center;
    line-height: 45px;
}
.bg-primary-soft {
    background-color: rgba(0, 0, 205, 0.1) !important;
    color: #6e00ff !important;
}
.bg-danger-soft {
    background-color: rgba(255, 86, 48, 0.11) !important;
    color: #ff5630 !important;
}
.bg-success-soft {
    background-color: rgba(7, 191, 88, 0.1) !important;
    color: #07bf58 !important;
}
.bg-warning-soft {
    background-color: rgba(239, 203, 113, 1) !important;
    color: #5F512D !important;
}
.font-30 {
    font-size: 30px !important;
}
.bg-primary {
    background-color: #6e00ff !important;
}
.form-check-input{
    margin-top: 1rem;
}